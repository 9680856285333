/** @jsx jsx */
import { jsx, Styled, Box, Flex, AspectImage, Text } from 'theme-ui';
import { Helmet } from 'react-helmet';

// import { imageSrc } from '../utils/image';

import Layout from '../components/layout';
import Timestamp from '../components/timestamp';
import Source from '../components/source';
import Link from '../components/link';

export default function Article({ pageContext: { item, nextItem } }) {
  const {
    content: contentHtml,
    url,
    title,
    fetched_at: fetchedAt,
    source,
    image,
    description,
  } = item;
  let content = source === 'SVT' ? `<p>${description}</p>` : '';
  content += contentHtml;
  // const src = imageSrc(image);
  const src = image;
  return (
    <Layout>
      <Helmet>
        <title>
          Njus / {source} / {title}
        </title>
        <meta name="description" content={description} />
      </Helmet>
      <article>
        <div
          sx={{
            marginLeft: '-2ch',
            marginRight: '-2ch',
          }}
        >
          <AspectImage ratio={16 / 9} src={src} />
        </div>
        <header sx={{ marginTop: '1em' }}>
          <Flex sx={{ alignItems: 'center', flexDirection: 'row' }}>
            <Source source={source} />
            <Timestamp date={fetchedAt} />
          </Flex>
          <Styled.h3>
            <Styled.a href={url}>{title}</Styled.a>
          </Styled.h3>
        </header>
        <section>
          <Text sx={{ a: { color: 'primary' } }}>
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </Text>
        </section>
        {nextItem && (
          <Box as="section" mt={4} mb={4}>
            <Text sx={{ fontStyle: 'italic' }}>Nästa artikel</Text>
            <Styled.h3>
              <Link href={`/${nextItem.path}`} title={nextItem.title} />
            </Styled.h3>
          </Box>
        )}
      </article>
    </Layout>
  );
}
